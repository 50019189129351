export default {
    list: {
        title: "expeditions",
        name: 'expeditions',
        class: "min-h-screen flex-1 mt-1 bg-gray-100 px-3 items-center",
        sousTable: true,
        options: {
            defaultPageSize: 5,
            actions: {
                isActive: true,
                name: "Actions",
                buttons: [{
                    text: "add_expedition",
                    icon: "add",
                    link: '/expeditions/new',
                    color: 'primary'
                }],
            }
        },
        columns: [
            {
                name: "ID",
                elements: [{
                    items: [{
                        name: "id",
                        class: "text-center",
                    }, ],
                }, ]
            },
            {
                name: "seller",
                elements: [{
                    items: [{
                        class: "ouka",
                        name: "seller",
                        elements: [{
                            items: [{
                                    name: "fullName",
                                    class: "font-bold text-gray-700",
                                    icone: "contacts"
                                },
                                // {
                                //     name: "email",
                                //     class: "font-light text-gray-700",
                                //     icone: "email"
                                // }
                            ]
                        }]

                    }, ],
                }, ]
            },
            {
                name: "Poids",
                elements: [{
                    items: [{
                        name: "weight",
                        class: "text-center font-bold",
                    }, ],
                }, ]
            },
            {
                name: "number_of_packages",
                elements: [{
                    items: [{
                        name: "numberOfPackages",
                        class: "text-center font-bold",
                    }, ],
                }, ]
            },
            {
                name: "transport_mode",
                elements: [{
                    items: [{
                        name: "transportMode",
                        class: "text-center font-bold",
                    }, ],
                }, ]
            },
            {
                name: "details",
                model: "expedition",
                title: 'Products'
            },
            // {
            //     name: "carrier",
            //     elements: [{
            //         items: [{
            //             class: "ouka",
            //             name: "carrier",
            //             elements: [{
            //                 items: [{
            //                         name: "name",
            //                         class: "font-bold",
            //                         icone: "contacts"
            //                     },
            //                     {
            //                         name: "phone",
            //                         class: "font-light text-gray-700",
            //                         icone: "local_phone"
            //                     }
            //                 ]
            //             }]

            //         }, ],
            //     }, ]
            // },
            {
                name: "from",
                class: "",
                elements: [{
                    items: [{
                        class: "font-semibold",
                        name: "country",
                        type: "text",
                    }],
                }, ]
            },
            //  {
            //     name: "to",
            //     class: "",
            //     elements: [{
            //         items: [{
            //             class: "",
            //             name: "country_to",
            //             type: "text",
            //         }],
            //     }, ]
            // },
            {
                name: "expedition_date",
                class: "",
                elements: [{
                    items: [{
                        class: "",
                        date: true,
                        name: "date",
                        type: "text",
                    }],
                }, ]
            },
            {
                name: "reception_date",
                class: "",
                elements: [{
                    items: [{
                        class: "",
                        date: true,
                        name: "arrivalDate",
                        type: "text",
                    }],
                }, ]
            },
            {
                name: "fees",
                class: "",
                elements: [{
                    items: [{
                        class: "font-semibold",
                        name: "fees",
                        type: "text",
                    }],
                }, ]
            },
            {
                name: "Type",
                class: "",
                elements: [{
                    items: [{
                        class: "",
                        name: "type",
                        options: (item) => {
                            switch (item) {
                                case 'expedition':
                                    return "color: rgb(233 147 19);"
                                    break;
                                case 'transfer':
                                    return "color:rgb(8, 194, 226)"
                                    break;
                            }
                        },
                    }],
                }, ]
            },
            {
                name: "status",
                class: "",
                elements: [{
                    items: [{
                        class: "border capitalize rounded-full text-gray-600 h-8 text-xs px-8 bg-white hover:border-gray-500 focus:outline-none appearance-none",
                        name: "status",
                        options: (item) => {
                            switch (item) {
                                case 'expedited':
                                    return "color: rgb(233 147 19); border-color: rgb(233 147 19);"
                                    break;
                                case 'received':
                                    return "color:rgb(58 217 18); border-color: rgb(58 217 18);"
                                    break;
                                case 'cancelled':
                                    return "color: rgb(255, 5, 5); border-color: rgb(255, 5, 5);"
                                    break;
                            }
                        },
                    }],
                }, ]
            },
        ],
        columnsDialog: {
            details: 'details',
            elements: [{
                    class: "",
                    name: "image",
                    referent: "details",
                    attribut: 'product',
                    items: [{
                        name: "picture",
                    }, ]
                },
                {
                    class: "",
                    name: "name",
                    referent: "details",
                    attribut: 'product',
                    items: [{
                        name: "name",
                    }, ]
                },
                // {
                //     class: "",
                //     name: "commodity_value",
                //     referent: "details",
                //     items: [{
                //         name: "merchandise_value",
                //     }, ]
                // },
                {
                    class: "",
                    name: "quantity_sent",
                    referent: "details",
                    attribut: 'quantity',
                    items: [{
                        name: "sent",
                    }, ]
                },
                {
                    class: "",
                    referent: "details",
                    name: "quantity_received",
                    attribut: 'quantity',
                    items: [{
                        name: "received",
                        name_1: "defective",
                    }, ]
                },
                // {
                //     class: "",
                //     referent: "details",
                //     name: "Quantity defective",
                //     attribut: 'quantity',
                //     items: [{
                //         name: "defective",
                //     }, ]
                // },
            ]
        },
        actions: {
            name: "actions",
            width: "10px",
            class: "justify-end",
            elements: [{
                    icone: "remove_red_eye",
                    color: "success",
                    key: 'read',
                    id: "",
                    role: true,
                    link: '/expeditions/detail',
                    tooltip: "Update",
                },
                {
                    icone: "edit",
                    color: "success",
                    role: true,
                    key: 'update',
                    link: '/expeditions/edit',
                    tooltip: "Update",
                },
                {
                    icone: "delete",
                    color: "danger",
                    role: true,
                    key: 'delete',
                    tooltip: "Delete",
                }
            ],
        },
        filters: {
            name: 'expeditions',
            elements: {
                search: {
                    options: {
                        icon: "search",
                        label: "search",
                        class: "",
                        hint: "search_expedition",
                    },
                    value: null,
                    type: "search",
                    validate: {
                        trim: true,
                        required: false,
                        minlength: 0,
                    }
                },
                champs: [{
                        label: "status",
                        key: "status",
                        value: null,
                        values: [],
                        class: 'py-2 text-xs  capitalize px-2 outline-none block h-full w-full bg-white',
                        type: "select",
                    },
                    {
                        label: "received status",
                        key: "status_received",
                        name: "name",
                        value: null,
                        values: [],
                        class: 'py-2 text-xs  capitalize px-2 outline-none block h-full w-full bg-white',
                        type: "select",
                    },
                    {
                        label: "seller",
                        key: "seller",
                        name: "fullName",
                        value: null,
                        values: [],
                        class: 'py-2 text-xs  capitalize px-2 outline-none block h-full w-full bg-white',
                        type: "select",
                    },
                    {
                        label: "transport_mode",
                        key: "transportMode",
                        value: null,
                        values: [],
                        class: 'py-2 text-xs  capitalize px-2 outline-none block h-full w-full bg-white',
                        type: "select",
                    },
                    {
                        label: "Expedition date",
                        key: "Range_date",
                        value: null,
                        placeholder: "Date Range ...",
                        type: "date_range",
                        class: "py-2 text-xs px-2 outline-none block h-full w-full"
                    },
                   
                ],
            },
        },
    },
}